/* app/page.js | Trends page with monthly patterns and statistics | Sree | 24 Nov 2024 */

'use client';

import { useMemo } from 'react';
import { Title, Stack, Text, Paper, Grid, Group } from '@mantine/core';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, ReferenceLine, ReferenceArea, Tooltip, Cell } from 'recharts';
import FxLoadingSkeleton from './components/FxLoadingSkeleton';
import FxLayout from './components/FxLayout';
import { useDataLoader } from './components/FxUtils';

// Constants
const COLORS = {
  fasting: { normal: '#40A850', warning: '#F84848' },
  postprandial: { normal: '#44639f', warning: '#FF5252' },
  reference: { area: '#E8F5E9', line: '#E81818' },
  axis: { line: '#BDBDBD', text: '#616161' }
};

const RANGES = { fasting: { min: 80, max: 130 }, postprandial: { max: 200 } };

// Stats Configuration
const STATS_CONFIG = [
  { label: 'Quarterly Average', key: 'quarterlyAvg', format: v => Number(v).toFixed(1) },
  { label: 'Quarterly High', key: 'quarterlyHigh', format: v => v },
  { label: 'Monthly Average', key: 'monthlyAvg', format: v => Number(v).toFixed(1) },
  { label: 'Monthly High', key: 'monthlyHigh', format: v => v },
];

// Stats Utility Functions
const calculateStats = (data) => {
  if (!data?.length) return {};
  const now = new Date(), threeMonthsAgo = new Date(now.getFullYear(), now.getMonth() - 3, now.getDate());
  const filteredData = data.reduce((acc, entry) => {
    const date = new Date(entry.values[0]), value = Number(entry.values[2]);
    if (isNaN(value)) return acc;
    if (date >= threeMonthsAgo) acc.quarterly.push(value);
    if (date.getMonth() === now.getMonth() && date.getFullYear() === now.getFullYear()) acc.monthly.push(value);
    return acc;
  }, { quarterly: [], monthly: [] });

  return {
    quarterlyAvg: filteredData.quarterly.length ? filteredData.quarterly.reduce((a, b) => a + b) / filteredData.quarterly.length : null,
    monthlyAvg: filteredData.monthly.length ? filteredData.monthly.reduce((a, b) => a + b) / filteredData.monthly.length : null,
    quarterlyHigh: filteredData.quarterly.length ? Math.max(...filteredData.quarterly) : null,
    monthlyHigh: filteredData.monthly.length ? Math.max(...filteredData.monthly) : null  // Changed to get monthly high instead of quarterly low
  };
};

/* Custom Tooltip */
const CustomTooltip = ({ active, payload }) => {
  if (!active || !payload?.length) return null;
  const data = payload[0]?.payload;
  const date = new Date(data.fullDate).toLocaleDateString('en-US', {
    weekday: 'short', month: 'short', day: 'numeric'
  });

  return (
    <Paper p="xs" shadow="md" style={{ background: 'white', border: '1px solid #eee' }}>
      <Stack gap={4}>
        <Text>{date}</Text>
        {data.fasting !== null && (
          <Text size="sm" c={data.fasting >= RANGES.fasting.min && data.fasting <= RANGES.fasting.max ? 'green' : 'red'}>
            Fasting: {data.fasting} mg/dL
          </Text>
        )}
        {data.postprandial !== null && (
          <Text size="sm" c={data.postprandial <= RANGES.postprandial.max ? 'blue' : 'red'}>
            Postprandial: {data.postprandial} mg/dL
          </Text>
        )}
        {data.notes && <Text size="xs" c="dimmed">{data.notes}</Text>}
      </Stack>
    </Paper>
  );
};
/* - - - - - - - - - - - - - - - - */

/* Process Monthly Data */
const processMonthlyData = (data) => {
  if (!Array.isArray(data) || !data.length) return [];

  const monthlyData = data.reduce((acc, entry) => {
    const date = new Date(entry.values[0]);
    const monthKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;

    if (!acc[monthKey]) {
      acc[monthKey] = Array(31).fill().map((_, i) => ({
        day: i + 1,
        fullDate: null,
        fasting: null,
        postprandial: null,
        notes: '',
        displayDay: i % 5 === 0 ? i + 1 : ''
      }));
    }

    const dayIndex = date.getDate() - 1;
    if (dayIndex >= 0 && dayIndex < 31) {
      acc[monthKey][dayIndex] = {
        ...acc[monthKey][dayIndex],
        fullDate: entry.values[0],
        fasting: parseFloat(entry.values[2]) || null,
        postprandial: parseFloat(entry.values[3]) || null,
        notes: entry.values[4] || ''
      };
    }
    return acc;
  }, {});

  // Sort in descending order
  return Object.entries(monthlyData)
    .sort(([a], [b]) => b.localeCompare(a))
    .map(([month, data]) => ({ month, data }));
};
/* - - - - - - - - - - - - - - - - */

/* Month Chart Component */
const MonthChart = ({ month, data }) => {
  if (!month || !data) return null;
  const monthTitle = new Date(`${month}-01`).toLocaleString('default', { month: 'long', year: 'numeric' });

  const renderBar = (dataKey, size, colorType) => (
    <Bar dataKey={dataKey} barSize={size}>
      {data.map((entry, index) => (
        <Cell
          key={`${dataKey}-${month}-${index}`}
          fill={entry[dataKey] === null ? 'transparent' :
            (dataKey === 'fasting' ?
              (entry.fasting >= RANGES.fasting.min && entry.fasting <= RANGES.fasting.max) :
              (entry.postprandial <= RANGES.postprandial.max)) ?
              COLORS[colorType].normal : COLORS[colorType].warning}
        />
      ))}
    </Bar>
  );

  return (
    <Paper p="md" shadow="sm" radius="md">
      <Title order={3} mb="xl">{monthTitle}</Title>
      <div style={{ height: 300 }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={data}
            margin={{ top: 20, right: 30, bottom: 20, left: 0 }}
            barGap={0}
            barCategoryGap={8}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis
              dataKey="displayDay"
              stroke={COLORS.axis.line}
              tick={{ fill: COLORS.axis.text, fontSize: 10 }}
              tickMargin={5}
            />
            <YAxis hide domain={[0, 250]} />
            <Tooltip content={<CustomTooltip />} cursor={false} isAnimationActive={false} />
            <ReferenceArea y1={RANGES.fasting.min} y2={RANGES.fasting.max} fill={COLORS.reference.area} fillOpacity={0.3} />
            <ReferenceLine y={RANGES.postprandial.max} stroke={COLORS.reference.line} strokeDasharray="3 3" />
            {renderBar('fasting', 20, 'fasting')}
            {renderBar('postprandial', 14, 'postprandial')}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </Paper>
  );
};
/* - - - - - - - - - - - - - - - - */

/* Main Component */
export default function TrendsPage() {
  const { data, loading, error } = useDataLoader();
  const monthlyCharts = useMemo(() => processMonthlyData(data), [data]);
  const stats = useMemo(() => calculateStats(data), [data]);

  if (loading) return <FxLayout><FxLoadingSkeleton /></FxLayout>;
  if (error) return <FxLayout><Text c="red">{error}</Text></FxLayout>;
  if (!data?.length) return <FxLayout><Text>No data available</Text></FxLayout>;

  return (
    <FxLayout>
      <Stack gap="lg" className="max-w-[1280px] mx-auto">
        <Group justify="space-between" align="flex-end">
          <Stack gap={4}>
            <Title order={3} c="dimmed" fw={500}>Trends</Title>
            <Text c="dimmed" size="sm">Review Summary & Track Historical Patterns</Text>
          </Stack>
        </Group>

        <Grid>
          {STATS_CONFIG.map(({ label, key, format }) => (
            <Grid.Col key={key} span={{ base: 6, sm: 6, md: 3 }}>
              <Paper withBorder shadow="0" p="md" radius="md">
                <Stack gap={4}>
                  <Text size="sm" fw={500} c="dimmed">{label}</Text>
                  <Text size={{ base: 'lg', md: 'xl' }} fw={700}>{stats[key] ? format(stats[key]) : 'N/A'}</Text>
                </Stack>
              </Paper>
            </Grid.Col>
          ))}
        </Grid>
        {/* <Grid>
          {STATS_CONFIG.map(({ label, key, format }) => (
            <Grid.Col key={key} span={{ base: 12, sm: 6, md: 3 }}>
              <Paper withBorder shadow="0" p="md" radius="md" >
                <Text fw={500}>{label}</Text>
                <Text size="xl" fw={700}>{stats[key] ? format(stats[key]) : 'N/A'}</Text>
              </Paper>
            </Grid.Col>
          ))}
        </Grid> */}

        <Grid>
          {monthlyCharts.map(({ month, data }) => (
            <Grid.Col key={month} span={12}>
              <MonthChart month={month} data={data} />
            </Grid.Col>
          ))}
        </Grid>
      </Stack>
    </FxLayout>
  );
}
/* - - - - - - - - - - - - - - - - */